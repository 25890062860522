<template>
  <article
    :class="['banner', `banner--${banner?.ContentPosition}`, { 'banner--overlay': hasOverlay }]"
  >
    <component
      :is="getElement"
      class="banner__link"
      :to="banner?.File?.Link"
    >
      <div class="banner__visual">
        <picture
          v-if="banner?.File?.Media?.url && banner?.File?.Media?.mime?.includes('image')"
          class="banner__picture"
        >
          <source
            :srcset="widescreenImage"
            media="(min-width: 1280px)"
          >
          <source
            :srcset="desktopImage"
            media="(min-width: 1024px)"
          >
          <source
            :srcset="tabletImage"
            media="(min-width: 768px)"
          >
          <source
            :srcset="phabletImage"
            media="(min-width: 640px)"
          >
          <source
            :srcset="mobileImage"
          >
          <img
            class="banner__image"
            :src="widescreenImage"
            :alt="banner?.File?.Media?.alternativeText || banner?.Name || ''"
            :width="banner?.File?.Media?.width"
            :height="banner?.File?.Media?.height"
            :loading="lazy ? 'lazy' : 'eager'"
            :fetchpriority="lazy ? 'low' : 'high'"
            :decoding="lazy ? 'async' : 'sync'"
          >
        </picture>
        <div
          v-else-if="banner?.File?.Media?.url && banner?.File?.Media?.mime?.includes('video')"
          class="banner__video"
        >
          <UiVideo
            v-if="banner?.File?.Media?.url"
            :class="{ 'mq--desktop-plus': banner?.File?.Media?.url && banner?.File?.MediaMobile?.url }"
            :src="banner?.File?.Media?.url"
            :alt="banner?.File?.Media?.alternativeText || banner?.Name"
            :width="banner?.File?.Media?.width"
            :height="banner?.File?.Media?.height"
            :loading="lazy ? 'lazy' : 'eager'"
            :fetchpriority="lazy ? 'auto' : 'high'"
            :controls="false"
            autoplay
            muted
            loop
          />
          <UiVideo
            v-if="banner?.File?.MediaMobile?.url && banner?.File?.MediaMobile?.mime?.includes('video')"
            class="mq--mobile-phablet"
            :src="banner?.File?.MediaMobile?.url"
            :alt="banner?.File?.MediaMobile?.alternativeText || banner?.Name"
            :width="banner?.File?.MediaMobile?.width"
            :height="banner?.File?.MediaMobile?.height"
            :loading="lazy ? 'lazy' : 'eager'"
            :fetchpriority="lazy ? 'auto' : 'high'"
            :controls="false"
            autoplay
            muted
            loop
          />
        </div>
      </div>

      <div class="banner__container container">
        <div class="banner__content">
          <!-- {{ banner.ContentPosition }} -->

          <div
            v-if="banner?.Title"
            class="banner__heading heading heading--1"
          >
            {{ banner.Title }}
          </div>

          <p
            v-if="banner?.Description"
            class="banner__description"
          >
            {{ banner.Description }}
          </p>

          <component
            :is="getButtonElement"
            v-if="banner?.ButtonText"
            class="banner__button button"
            :class="`button--${banner?.ButtonType}`"
            :to="banner?.File?.Link"
          >
            <span>{{ banner?.ButtonText }}</span>
          </component>
        </div>
      </div>
    </component>
  </article>
</template>

<script setup lang="ts">
import { AppLink } from '#components'

const props = withDefaults(defineProps<{
  banner: any
  lazy: boolean
  bannerCount?: number
  fullWidth?: boolean
}>(), {
  bannerCount: 1,
  fullWidth: false,
})

const getElement = computed(() => {
  if (props?.banner?.File?.Media?.mime?.includes('video')) {
    return 'div'
  }

  if (props?.banner?.File?.Link) {
    return AppLink
  }

  return 'div'
})

const getButtonElement = computed(() => {
  if (props?.banner?.File?.Media?.mime?.includes('video') && props?.banner?.File?.Link) {
    return AppLink
  }

  return 'span'
})

const hasOverlay = computed(() => {
  const hasContent = props?.banner?.Title || props?.banner?.Description
  const contentAboveImage = ['topleft', 'top', 'topright', 'left', 'center', 'right', 'bottomleft', 'bottom', 'bottomright'].includes(props?.banner?.ContentPosition)
  return hasContent && contentAboveImage
})

const widescreenImage = computed(() => {
  const columns = Math.min(props?.bannerCount, 3)
  const maxWidth = Math.round((props.fullWidth ? 2560 : 1324) / columns)
  const url = props?.banner?.File?.Media?.url + '?f=rs:fit:' + maxWidth
  return url
})

const desktopImage = computed(() => {
  const columns = Math.min(props?.bannerCount, 3)
  const maxWidth = Math.round(1280 / columns)
  const url = props?.banner?.File?.Media?.url + '?f=rs:fit:' + maxWidth
  return url
})

const tabletImage = computed(() => {
  const columns = Math.min(props?.bannerCount, 2)
  const maxWidth = Math.round(1024 / columns)
  const url = props?.banner?.File?.Media?.url + '?f=rs:fit:' + maxWidth
  return url
})

const phabletImage = computed(() => {
  const maxWidth = 768
  const url = (props?.banner?.File?.MediaMobile?.url || props?.banner?.File?.Media?.url) + '?f=rs:fit:' + maxWidth
  return url
})

const mobileImage = computed(() => {
  const maxWidth = 640
  const url = (props?.banner?.File?.MediaMobile?.url || props?.banner?.File?.Media?.url) + '?f=rs:fit:' + maxWidth
  return url
})
</script>

<style src="~layers/app/components/Strapi/Banner.css" />
